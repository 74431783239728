<template>
  <div class="all">
    <div class="background">
      <img v-if="enterpriseObj" class="logo" :src="enterpriseObj.config.loginBackground" style="width: 100%;height: 100%;"
        alt="" />
      <img v-else src="../../assets/login/bg-login.png" style="width: 100%;height: 100%;" alt="" />
    </div>
    <div class="content">
      <img v-if="enterpriseObj" class="logo" :src="enterpriseObj.config.homeLogo" alt="" width="404px" height="120px" />
      <div class="content-login">
        <!-- <el-tabs :tab-position="tabPosition" style="height: 590px;"> -->
        <!-- <el-tab-pane>
            <span slot="label" class="ok">
              <i style="padding-right: 15px" class="el-icon-user"></i>
              个人
            </span> -->
        <div class="enroll-ren">
          <div class="content-login-header">
            <img src="../../assets/login/xian-left.png">
            <h3>注册</h3>
            <img src="../../assets/login/xian-right.png">
          </div>
          <div class="login-input" style="width: 100%;">
            <span>手机号</span>
            <el-input placeholder="请输入手机号" v-model="enrollDate.phone" clearable>
            </el-input>
          </div>
          <!-- <div class="login-input" style="width: 100%;">
            <span>用户名</span>
            <el-input placeholder="必须是6-10位字母、数字组成，不能以数字开头" v-model="enrollDate.username" clearable
              oninput="value=value.replace(/[\u4E00-\u9FA5]/g,'')">
            </el-input>
          </div> -->
          <div class="login-input" style="width: 100%;">
            <span>验证码</span>
            <div class="login-ma">
              <el-input placeholder="请输入验证码" v-model="enrollDate.code" clearable>
              </el-input>
              <el-button type="primary" style="margin-left: 10px" @click="openCodePage" :disabled="totalTime < 60">
                {{ content }}
              </el-button>
              <!--<el-button>发送验证码</el-button>-->
            </div>
          </div>
          <div class="login-input" style="width: 100%;">
            <span>密码</span>
            <el-input placeholder="密码必须含大小字母数字特殊字符8位以上" clearable v-model="enrollDate.password" show-password></el-input>
          </div>
          <!-- <div class="login-input" style="width: 100%;">
            <span>密码验证</span>
            <el-input placeholder="请再次输入密码" clearable v-model="password2" show-password></el-input>
          </div> -->

          <div class="login-auto" style="width: 100%;">
                    <div>
                        <el-checkbox v-model="checked">勾选同意</el-checkbox>
                    <span @click="userAgreement()" style="color: #409eff;cursor: pointer;">《用户协议》</span>
                    <span @click="privacyPolicy()" style="color: #409eff;cursor: pointer;">《隐私政策》</span>
                    </div>
                </div>
          <el-button class="btn" type="primary" @click="toLogin">注册</el-button>
          <div class="login-toenroll">
            已经有账户?
            <span @click="goLogin" style="cursor:pointer; ">登录</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :before-close="closeCodePage" title="请输入验证码" :visible.sync="dialogVisible" width="434px"
      style="margin-top: 12%;">
      <div>
        <!-- <span style="display: block;">验证码</span> -->
        <el-input placeholder="请输入验证码" clearable v-model="enrollDate.captcha"
          style="width: 200px;text-align: center;"></el-input>
        <img style="float: right;" @click="getUuid" :src="codeUrl">
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeCodePage()">取 消</el-button>
        <el-button type="primary" @click="getCountdown">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { adornUrl, httpNPGet, httpPost } from "@/utils/httpRequest";
import { isPhone } from "@/utils/validate";

export default {
  name: "login",
  data() {
    return {
      enrollDate: {
        phone: '',
        password: '',
        code: '',
        username: '',
        captcha: '',
      },
      status: '',
      checked: false,
      password2: '',
      // tabPosition: 'left',
      totalTime: 60,
      content: "发送验证码",
      logoImg: '',
      enterpriseObj: {},
      dialogVisible: false,
      codeUrl: '',
    }
  },
  created() {
    this.enterpriseObj = this.$store.getters.enterpriseObj;
  },
  mounted() {
    this.getUuid();
    this.logoImg = localStorage.getItem("logoImg")
  },
  methods: {
    // 用户协议
    userAgreement(){
      let routeUrl = this.$router.resolve({
        path: '/userAgreement'
      })
      window.open(routeUrl.href, '_blank')
    },
    // 隐私政策
    privacyPolicy(){
      let routeUrl = this.$router.resolve({
        path: '/privacyPolicy'
      })
      window.open(routeUrl.href, '_blank')
    },
    // 获取uuid
    getUuid() {
      httpNPGet("/captcha/uuid", (res) => {
        let uuid = res.data.uuid;
        this.enrollDate.uuid = uuid;
        this.codeUrl = adornUrl("/captcha/img?uuid=" + uuid);
      }, (err) => {

      })
    },
    // 打开验证码弹窗
    openCodePage() {
      this.codeUrl = "";
      this.getUuid()
      if ((this.enrollDate.phone + "").length == 11) {
        this.dialogVisible = true;
      } else {
        // console.log((this.enrollDate));
        this.$message.error('请填写正确的手机号');
      }
    },
    // 60s 倒计时
    getCountdown() {
      if ((this.enrollDate.phone + "").length == 11) {
        this.getMa();
        let clock = window.setInterval(() => {
          this.content = this.totalTime + 's后重新发送';
          this.totalTime--;
          if (this.totalTime < 0) {
            this.totalTime = 60;
            this.content = "重新发送验证码";
            window.clearInterval(clock);
          }
        }, 1000);
      } else {
        this.$message.error('请填写正确的手机号');
      }
    },

    // 验证码
    getMa() {
      httpPost("/captcha/send-logon",
        this.enrollDate
        , (res) => {
          this.closeCodePage()
          // console.log(res)
        }, (err) => {
          this.$message.error(err.msg);
          this.totalTime = 0
          throw err
        })
    },
    // 关闭验证码弹窗
    closeCodePage() {
      this.dialogVisible = false;
      this.enrollDate.captcha = ''
    },

    // 注册接口
    toLogin() {
      // this.$router.push('/authentication')
      if (this.enrollDate.code!= '') {
          if (this.checked) {
            if ((this.enrollDate.phone + "").length==11){
               // 定义密码规则：至少一个小写字母、一个大写字母、一个数字和一个特殊字符，且长度至少为8
                var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$");

                if (!strongRegex.test(this.enrollDate.password)) {
                    this.$message.error('密码强度不符合要求：至少一个小写字母、一个大写字母、一个数字和一个特殊字符，且长度至少为8');
                    return;
                }
                httpPost("/app/app-user/insert-user",
                {
                  ...this.enrollDate,
                  enterpriseId: this.$store.getters.enterpriseObj.id
                }
                , (res) => {
                  this.$router.push('/login')
                  // console.log(res)
                  this.$message({
                    message: '注册成功',
                    type: 'success'
                  });
                  localStorage.setItem("userId",res.data.userId)
                }, (err) => {
                  if(err.code==500){
                    if(err.msg=="token异常，请重新登录"){
                      localStorage.clear();
                      if(!localStorage.getItem("userId")){
                        this.$message.error('登录过期，请重新登录!')
                        this.$router.push("/login")
                      }
                    }
                  }
                  this.$message.error(err.msg)
                  if(this.$message == "手机号已存在"){
                    this.totalTime = 0
                  }
                })
            }else{
              this.$message.error('请输入11位手机号');
            }
          } else {
            this.$message.error('请阅读并接受');
          }

      } else {
        this.$message.error('请填写手机号或验证码');
      }
      // console.log(this.enrollDate)
    },

    // 页面跳转
    goLogin() {
      this.$router.push('/login')
    }
  },

}
</script>

<style lang="scss" scoped>
.all {

  .background {
    width: 100%;
    height: 100%;
    /**宽高100%是为了图片铺满屏幕 **/
    z-index: -1;
    position: absolute;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 615px;
    height: 570px;
    align-items: center;
    //box-sizing: border-box;
    //background-color: #fff;
    z-index: 1;
    position: absolute;
    top: -160px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    .logo {
      width: 370px;
    }

    .content-login {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 480px;
      height: 500px;
      margin-top: 25px;
      padding-top: 10px;
      background: #FFF;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.01), 0px 4px 11px 0px rgba(0, 0, 0, 0.02), 0px 10px 27px 0px rgba(0, 0, 0, 0.03);
      border-radius: 20px;

      //.ok {
      //  background: linear-gradient(270deg, rgba(9,112,213,0) 0%, #0970D5 100%);
      //  border-radius: 0px 0px 0px 0px;
      //  opacity: 1;
      //  border: 1px solid #1371E7;
      //}

      .enroll-ren {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 435px;
        height: 590px;
        background: #FFFFFF;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        box-sizing: border-box;
        padding: 32px 34px;
      }

      .content-login-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;

        h3 {
          font-size: 28px;
          margin: 0 48px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
        }

        img {
          width: 92px;
          height: 2px;
          background: linear-gradient(287deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
        }
      }

      .login-input {
        span {
          width: 100%;
          text-align: left;
          display: block;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          padding-bottom: 8px;
        }

        .login-ma {
          display: flex;
        }
      }

      .login-auto {
        display: flex;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        margin: 5px 0;
      }

      .login-toenroll {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #667280;

        span {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #1371E7;
        }
      }

      .btn {
        display: block;
        width: 100%;
        height: 40px;
        //line-height: 40px;
        text-align: center;
        background: #1371E7;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
        border-radius: 2px 2px 2px 2px;
      }
    }
  }
}
</style>
